.tournament-update-container {
  min-height: 100vh; 
  padding: 20px;
  background-color: black;
  display: flex;
  flex-direction: column;
}


h1 {
  color: white;
}

p {
  color: white;
}

.round-container {
  display: flex;
  flex-direction: column;
  gap: 0px; 
  justify-content: center; 
  align-items: flex-start;
  padding: 20px;
}

.round {
  display: flex;
  flex-direction: row;
  align-items: center; 
  padding: 15px;
  border: 1px solid red; 
  border-radius: 8px;
  margin-top: 20px;
}

.arrow {
  position: relative;
  background-color: #A7A9BE;
  width: 80px; 
  height: 3px;
}

.arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: 6px solid transparent;
  border-left-color: #A7A9BE;
  border-right: 0;
  margin-left: -3px;
}


.fixture-container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
}

.match {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  width: 300px;
}

.player {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #3F3E3E;
  border-radius: 5px;
  height: 50px;
}

.first {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top: 1px solid #A7A9BE;
  border-left: 1px solid #A7A9BE;
  border-right: 1px solid #A7A9BE;
  border-bottom: 0px solid #A7A9BE;
}

.second {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #A7A9BE;
}

.player-info {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;  
  flex-grow: 1;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.player-name {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.score {
  font-size: 18px;
  font-weight: bold;
  color: #1E1B1B;
}

.first-score {
  display: flex;
  align-items: center;
  background-color: #A7A9BE;
  padding-left: 12px;  
  padding-right: 12px;
  height: 100%;
  width: 40px;
}

.second-score {  
  display: flex;
  align-items: center;
  background-color: red;
  padding-left: 12px;  
  padding-right: 12px;
  height: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0px;
  width: 40px;
}

.first.single-participant {
  border-radius: 5px;
  border: 1px solid #a7a9be;
}

.score-input {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  font-size: inherit;
  text-align: center;
  width: 100%;
  box-sizing: border-box; 
}

.update-btn {
  background-color: red;
  color: #fff;
  padding: 13px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
}

.update-btn:hover {
  background-color: rgb(49, 43, 6);
}

.update-btn-mobile {
  display: none;
}

.fixtures-update {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shuffle-btn {
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: #fff;
  padding: 13px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
}


@media (max-width: 768px) {
  .round {
    flex-direction: column;
  }

  .round-container{
    flex-direction: column;
    align-items: center;
  }

  .update-btn {
    display: none;
    margin-bottom: 30px;
  }

  .update-btn-mobile {
    display: flex;
    margin-bottom: 30px;
    background-color: red;
    color: #fff;
    padding: 13px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .update-btn-mobile:hover {
    background-color: rgb(49, 43, 6);
  }

  .fixtures-update {
    flex-direction: column;
  }

  .arrow {
    width: 3px; 
    height: 80px;
  }

  .arrow::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-24%);
    border: 6px solid transparent;
    border-top-color: #A7A9BE;
    margin-top: -1px;
  }

  .fixture-container {
    margin-top: 0px;
  }
}